import React from "react"
import Seo from "../../components/seo"

import OtherServices from "../../components/Content/OtherServices"

export default props => (
  <React.Fragment>
    <Seo title={`Servicios`} />
    <OtherServices location={props.location} />
  </React.Fragment>
)
